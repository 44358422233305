/* Global Styles */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom Global Styles */
body {
  @apply font-sans antialiased text-gray-900 bg-gray-100;
}

/* Form Inputs */
.form-input {
  @apply w-full px-3 py-2 border border-gray-300 rounded-lg 
         focus:outline-none focus:ring-2 focus:ring-blue-500 
         transition duration-300 ease-in-out;
}

/* Buttons */
.btn-primary {
  @apply w-full bg-blue-600 text-white py-2 rounded-lg 
         hover:bg-blue-700 transition duration-300 
         ease-in-out transform hover:scale-105 
         focus:outline-none focus:ring-2 focus:ring-blue-500;
}

/* Links */
.link {
  @apply text-blue-600 hover:text-blue-800 
         transition duration-300 cursor-pointer;
}

/* Container */
.auth-container {
  @apply min-h-screen flex items-center justify-center 
         bg-gradient-to-br from-blue-100 to-blue-300 p-4;
}

.auth-card {
  @apply bg-white shadow-2xl rounded-2xl p-8 
         w-full max-w-md;
}

.auth-title {
  @apply text-3xl font-bold text-center text-blue-800 mb-6;
}

/* Form Layout */
.auth-form {
  @apply space-y-4;
}

.form-group {
  @apply mb-4;
}

.form-label {
  @apply block text-sm font-medium text-gray-700 mb-2;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .auth-card {
    @apply w-full mx-4 p-6;
  }
  
  .auth-title {
    @apply text-2xl;
  }
}

/* Accessibility Enhancements */
input:focus {
  @apply outline-none ring-2 ring-blue-500;
}

/* Animation Keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-out;
}